<template>
  <!-- pt-18 pb-10 lg:py-18 -->
  <div
    class="grid grid-cols-1 lg:grid-cols-2 gap-10 lg:gap-16 px-4 lg:px-8 max-w-screen-lg mx-auto"
  >
    <div class="hidden lg:block">
      <p
        class="title text-np-56 lg:text-np-3xl text-left saoltext"
        v-html="title"
      ></p>
      <p class="text-np-56 lg:text-np-3xl text-left saoltext">
        <slot name="title"></slot>
      </p>
    </div>
    <div v-if="description" class="space-y-4">
      <p
        class="text-np-green text-np-base text-left font-light space-y-4"
        v-html="description"
      ></p>
      <div v-if="exploreLink" class="flex text-left">
        <neera-anchor :linkTo="exploreLink">
          {{ exploreTitle }}
        </neera-anchor>
      </div>
      <slot></slot>
    </div>
    <slot></slot>
  </div>
</template>
<script>
import NeeraAnchor from "./NeeraAnchor.vue";

export default {
  components: { NeeraAnchor },
  name: "CollapsibleQuoteBreak",
  props: {
    exploreLink: {
      type: [String],
      default: "",
    },
    exploreTitle: {
      type: [String],
      default: "",
    },
    title: {
      type: [String],
    },
    description: {
      type: [String],
    },
  },
};
</script>

<style scoped>
.title {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #905235;
}
</style>
