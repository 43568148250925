import apiClient from "@/api/client";

export const StaticFileSlugs = {
  aboutLocationFile: "neeraretreathotel-location",
  experienceEcoWeddingFloorplanFile: "neeraretreathotel-eco-wedding-floor-plan",
  experienceSpacesFloorplanFile: "neeraretreathotel-spaces-floor-plan",
  seasonBreakfastMenuFile: "neeraretreathotel-season-breakfast-menu",
  seasonLunchDinnerMenuFile: "neeraretreathotel-season-lunch-dinner-menu",
  slowbarMenuFile: "neeraretreathotel-slowbar-menu",
  roomBrochureFile: "neeraretreathotel-room-brochure",
  spaceBrochureFile: "neeraretreathotel-inspirational-space-brochure",
  ecoConsciousBrochureFile: "neeraretreathotel-eco-conscious-brochure"
}

export const getStaticFileBySlug = (slug) => {
  return apiClient.request({
    method: "GET",
    url: `api/static-files?filters[slug]=${slug}&populate=deep`,
  });
};
