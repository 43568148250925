<template>
  <div class="about" data-view>
    <hero-with-image
      title="“be the start of the ripple”"
      :neera-badge="true"
      pre-description="“simple elegance and kindness <span class='whitespace-nowrap'>to nature.</span>”"
      description="<p class='hidden lg:block'><span class='saoltext whitespace-nowrap'>‘neera retreat’</span> was carefully designed to perfectly balance contemporary aesthetics and environmental friendliness with its architecture in harmony with the local climate and surrounding geography.</p>"
      exploreLink=""
      :imageUrl="require('@/assets/about/01_ripple_effect.png')"
      class="mb-0 lg:mb-20 lg:mt-8"
    />
    <collapsible-quote-break
      data-aos="fade-up"
      class="mt-0 mb-18 lg:mt-20 lg:mb-20"
      title="about neera"
      description="<p><span class='saoltext whitespace-nowrap'>‘neera retreat’</span> was carefully designed to perfectly balance contemporary aesthetics and environmental friendliness. its architecture is in harmony with the local climate and the surrounding geography so that less energy is consumed. windows in all guestrooms can be opened to let in the fresh air. all electrical appliances, lighting fixtures, and air conditioners are certified with the high energy-saving capability.
</p>
<p>the hotel is also fitted with water-saving sanitary ware and furniture are considered to maximize lifespan.  trees are planted all around to reduce heat, create shade and generate oxygen. the paint used both internally and externally is a low level of VOC (volatile organic compounds) and thus largely free of gaseous chemicals that might cause harm. some leftover materials from the facilities’ construction were creatively upcycled, and discarded detritus is turned into interesting three-dimensional art pieces that inspire even as they optimize material efficiency.
</p><p>
we welcome all well-mannered (non-poisonous) pets and do not limit pet size that are on leash/in carrier/bag/trolley with the owner(s) at all times in our designated common areas and will be extending pet-friendly accommodations in 2023.<p>"
    />
    <div class="flex flex-col about-para2 flex py-18 lg:py-20 space-y-16">
      <div
        class="grid grid-cols-1 lg:grid-cols-2 px-4 lg:px-8 gap-16 lg:gap-16 max-w-screen-lg mx-auto"
      >
        <div data-aos="fade-up">
          <div class="flex flex-col justify-center w-full gap-y-8 lg:gap-y-4">
            <div
              class="card-height bg-np-green rounded-tr-np-lg order-last lg:order-first"
            >
              <img
                class="card-content h-full w-full object-cover rounded-tr-np-lg"
                src="@/assets/about/ABOUT_02-vision.png"
              />
            </div>
            <div
              class="flex flex-col text-left space-y-2 order-first lg:order-last"
            >
              <p class="saoltext font-light text-np-2xl">vision</p>
              <p class="font-light text-np-base">
                to be the pioneer of
                <span class="whitespace-nowrap">eco-conscious</span> hotel
                choice in Thailand, providing inspirational space to educate, a
                place where people will learn to be mindful in daily life, and
                ultimately to ripple behavior changes in humanity.
              </p>
            </div>
          </div>
        </div>
        <div data-aos="fade-up">
          <div class="flex flex-col justify-center w-full gap-y-8 lg:gap-y-4">
            <div
              class="card-height bg-np-green rounded-tr-np-lg order-last lg:order-first"
            >
              <img
                class="card-content h-full w-full object-cover rounded-tr-np-lg"
                src="@/assets/about/ABOUT_02-mission.png"
              />
            </div>
            <div
              class="flex flex-col text-left space-y-2 order-first lg:order-last"
            >
              <p class="saoltext font-light text-np-2xl">mission</p>
              <p class="font-light text-np-base">
                we want to be the start of
                <span class="whitespace-nowrap">“the ripple effect”</span> of
                <span class="whitespace-nowrap">eco-consciousness</span> and
                mindfulness by raising awareness to inspire people.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="space-y-8 my-18 lg:my-20" data-aos="fade-up">
      <div
        class="grid grid-cols-1 lg:gap-16 px-4 lg:px-8 max-w-screen-lg mx-auto"
      >
        <div class="text-left">
          <p class="text-np-glimpse title-italic text-np-56 lg:text-np-3xl">
            “be eco-conscious”
          </p>
          <div class="flex saoltext text-np-glimpse text-np-xl font-light">
            <div class="block my-auto w-content px-1">
              <svg
                width="12"
                height="2"
                viewBox="0 0 12 2"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 1L-2.98023e-07 1"
                  stroke="#5C623F"
                  stroke-width="0.5"
                />
              </svg>
            </div>
            <div class="saoltext">neera retreat hotel</div>
          </div>
        </div>
      </div>
      <div
        class="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-16 px-4 lg:px-8 max-w-screen-lg mx-auto"
      >
        <div
          class="text-base font-light saoltext text-np-green text-left space-y-4"
        >
          <p>
            <span class="saoltext whitespace-nowrap">‘neera retreat’</span>
            keeps its operations sustainable at every stage, from design to
            services and facilities that are environmentally friendly.
          </p>
          <p>
            energy is saved by using it efficiently, and waste is reduced almost
            to nil. discarded and leftover materials are creatively repurposed
            and reused, assuring the subtle inspiration for guests, instilling
            in all visitors a fresh awareness of ecological husbandry in the
            hopes that they will form their own concepts of change, both
            mentally and behaviorally, to act on later.
          </p>
          <router-link
            to="/about/eco-conscious"
            class="flex flex-row saoltext neera-text-glimps"
            ><div class="flex w-auto items-left pr-2">
              <p class="saoltext">view neera eco–consciousness</p>
              <div class="flex transition duration-200 anchor-arrow px-2">
                <img src="@/assets/neera-arrow.svg" />
              </div>
            </div>
          </router-link>
        </div>
        <div class="flex flex-col w-full text-left space-y-4">
          <ul class="text-np-24 font-light text-np-green text-left">
            <li>
              <div class="flex">
                <div class="flex px-2">
                  <img src="@/assets/arrow-neera-green.svg" />
                </div>
                <p class="saoltext">eco–water system</p>
              </div>
            </li>
            <li>
              <div class="flex">
                <div class="flex px-2">
                  <img src="@/assets/arrow-neera-green.svg" />
                </div>
                <p class="saoltext">eco–friendly energy saving</p>
              </div>
            </li>
            <li>
              <div class="flex">
                <div class="flex px-2">
                  <img src="@/assets/arrow-neera-green.svg" />
                </div>
                <p class="saoltext">eco–friendly food service</p>
              </div>
            </li>
            <li>
              <div class="flex">
                <div class="flex px-2">
                  <img src="@/assets/arrow-neera-green.svg" />
                </div>
                <p class="saoltext">eco–conscious hotel service</p>
              </div>
            </li>
            <li>
              <div class="flex">
                <div class="flex px-2">
                  <img src="@/assets/arrow-neera-green.svg" />
                </div>
                <p class="saoltext">eco–design</p>
              </div>
            </li>
            <li>
              <div class="flex">
                <div class="flex px-2">
                  <img src="@/assets/arrow-neera-green.svg" />
                </div>
                <p class="saoltext">eco–conscious air quality</p>
              </div>
            </li>
            <li>
              <div class="flex">
                <div class="flex px-2">
                  <img src="@/assets/arrow-neera-green.svg" />
                </div>
                <p class="saoltext">eco–conscious reducing waste</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div data-aos="fade-up" class="special-offer my-18 lg:my-20 mx-4 lg:mx-8">
      <photo-horizon :photos="special_offers" />
    </div>
    <quote-break
      data-aos="fade-up"
      class="my-18 lg:my-20"
      title='
    <p class="text-np-3xl saoltext">Nakhon Pathom</p>
    '
      explore-link="/about/neighborhoods"
      explore-title="view neighborhood"
      description="
    <p>
     just an hour drive from Bangkok, witness Nakhon Pathom, where culture, history, and nature melt in harmoniously with Tha Chin River, the blood vessel of the province. explore the riverside culture, visit organic farms, and taste the history through your taste buds.
    </p>
    "
    />
    <div data-aos="fade-up" class="flex flex-col lg:flex-row my-18 lg:my-20">
      <div class="flex relative">
        <gallery-item
          gallery-id="gallery-neera-green-space"
          :images="galleries"
        />
      </div>
    </div>
    <div
      data-aos="fade-up"
      class="my-18 lg:my-20 gap-x-10 lg:gap-x-16 px-4 lg:px-8 max-w-screen-lg mx-auto"
    >
      <div class="flex flex-col gap-y-6">
        <div class="text-left">
          <p
            class="saoltext font-light text-np-glimpse text-np-56 lg:text-np-3xl"
          >
            location
          </p>
        </div>

        <div class="flex flex-col gap-y-4">
          <div
            class="order-2 flex items-center justify-center gap-x-10 lg:gap-x-16"
          >
            <!-- <div class="flex items-center">
              <neera-solid-button
                class="flex-1"
                text="view google map"
                linkTo="/"
              /> -->
            <neera-solid-button
              text="download pdf"
              :linkTo="false"
              staticFileSlug="neeraretreathotel-location"
            />
            <!-- </div> -->
          </div>

          <div class="order-1">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.490815187897!2d100.24103661485498!3d13.749249001056887!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e2eb26d581f695%3A0x9e5900895a210596!2sneera%20retreat%20hotel!5e0!3m2!1sen!2sth!4v1652069522452!5m2!1sen!2sth"
              width="1024"
              height="500"
              style="border: 0; width: 100%"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import HeroWithImage from "../../components/HeroWithImage.vue";
import CollapsibleQuoteBreak from "../../components/CollapsibleQuoteBreak.vue";
import QuoteBreak from "../../components/QuoteBreak.vue";
import NeeraSolidButton from "../../components/NeeraSolidButton.vue";
import PhotoHorizon from "../../components/PhotoHorizon.vue";
import GalleryItem from "@/components/GalleryItem.vue";
import AOS from "aos";

export default {
  name: "About",
  components: {
    HeroWithImage,
    CollapsibleQuoteBreak,
    QuoteBreak,
    NeeraSolidButton,
    PhotoHorizon,
    GalleryItem,
  },
  created() {
    const title = "“be the start of the ripple”";
    const description =
      "simple elegance and kindness to nature. ‘neera retreat’ was carefully designed to perfectly balance contemporary aesthetics and environmental friendliness with its architecture in harmony with the local climate and surrounding geography.";
    // const titleEl = document.querySelector("head title");
    const mTitleEl = document.querySelector('head meta[name="title"]');
    const descEl = document.querySelector('head meta[name="description"]');
    const twTitleEl = document.querySelector('head meta[name="twitter:title"]');
    const twDescEl = document.querySelector(
      'head meta[name="twitter:description"]'
    );
    const ogTitleEl = document.querySelector('head meta[property="og:title"]');
    const ogDescEl = document.querySelector('head meta[property="og:description"]');
    // titleEl.textContent = title;
    mTitleEl.setAttribute("content", title);
    twTitleEl.setAttribute("content", title);
    ogTitleEl.setAttribute("content", title);
    descEl.setAttribute("content", description);
    twDescEl.setAttribute("content", description);
    ogDescEl.setAttribute("content", description);
  },
  mounted() {
    AOS.init({
      once: true,
      duration: 2000,
    });
  },
  setup() {
    const startDateValue = ref([]);
    const endDateValue = ref([]);
    const formatter = ref({
      date: "DD MMM YYYY",
      month: "MMM",
    });

    return {
      startDateValue,
      endDateValue,
      formatter,
    };
  },
  data() {
    return {
      galleries: [
        {
          image_url: require("@/assets/about/ABOUT_04_nakhonpathom_01.png"),
          mobile_image_url: require("@/assets/about/ABOUT_04_nakhonpathom_01.png"),
        },
        {
          image_url: require("@/assets/about/ABOUT_04_nakhonpathom_02.png"),
          mobile_image_url: require("@/assets/about/ABOUT_04_nakhonpathom_02.png"),
        },
        {
          image_url: require("@/assets/about/ABOUT_04_nakhonpathom_03.png"),
          mobile_image_url: require("@/assets/about/ABOUT_04_nakhonpathom_03.png"),
        },
        {
          image_url: require("@/assets/about/ABOUT_04_nakhonpathom_04.png"),
          mobile_image_url: require("@/assets/about/ABOUT_04_nakhonpathom_04.png"),
        },
        {
          image_url: require("@/assets/about/ABOUT_04_nakhonpathom_05.png"),
          mobile_image_url: require("@/assets/about/ABOUT_04_nakhonpathom_05.png"),
        },
      ],
      special_offers: [
        {
          image_url: require("@/assets/about/05_nakhon_Pathom_01.png"),
        },
        {
          image_url: require("@/assets/about/ABOUT_03_ecocons_02.png"),
        },
        {
          image_url: require("@/assets/about/ABOUT_03_ecocons_03.png"),
        },
      ],
    };
  },
};
</script>

<style scoped>
.title-italic {
  font-family: "Saol Italic" !important;
  font-style: italic;
  font-weight: 300;
}

.about-para2 {
  color: #f0efea;
  background: #994e2e;
}

button {
  background: #994e2e;
  border-radius: 10px;
  color: white;
}

.title-map {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
}

.title-para {
  font-family: "Saol Text" !important;
  font-style: italic;
  font-weight: 300;
}

a:hover .anchor-arrow {
  transform: translateX(0.25rem);
}
</style>
